import React from 'react'
import Helmet from 'react-helmet'

import Section from 'components/Section'
import Wrapper from 'components/Wrapper'
import Heading from 'components/Heading'

const Contact = () => (
  <>
    <Helmet title="Contact | François Deguire" />
    <Section>
      <Wrapper>
        <Heading>
          Hello.
          <br />
          Let's get in touch.
        </Heading>
      </Wrapper>
    </Section>
    <Section flushTop>
      <Wrapper>
        <p>
          <strong>
            <a href="mailto:deguire.francois@gmail.com">
              deguire.francois@gmail.com
            </a>
          </strong>
          <br />
          <strong>
            <a href="tel:15149534822">+1 514-953-4822</a>
          </strong>
        </p>
      </Wrapper>
    </Section>
  </>
)

export default Contact
